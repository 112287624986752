<template>
  <div class="about-page">
    <el-input v-model="about" type="textarea" :rows="4" placeholder="请输入 关于我们"/>
    <el-input v-model="fix" type="textarea" :rows="4" placeholder="请输入 维修服务" style="margin-top: 8px;"/>
    <el-button type="primary" @click="updateCompanyAbout" style="margin-top: 8px;">提交</el-button>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        about: '',
        fix: ''
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.about = data.data.about
          this.fix = data.data.fix
        })
      },
      updateCompanyAbout() {
        axios.put('/api/company/about', { about: this.about, fix: this.fix }).then(data => {
          if (data.data === 'success') {
            this.$notify.success('修改成功')
          } else {
            this.$notify.error('修改失败')
          }
        })
      }
    }
  }
</script>
